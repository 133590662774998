@import '../../../shared/styles/fonts.scss';

.wordSummaryContainer {
    padding: 20px 25px;
    border-radius: 15px;
    position: relative;
    border: 2px solid #111725;
    display: flex;
    flex-direction: column;

    svg {
        fill: #3f4f7b;
        font-size: 120px;
        position: absolute;
        bottom: -15px;
        right: 0;
        opacity: 0.3;
    }

    .wordSummaryMain {
        border-bottom: 1px solid #1a2133;
        padding-bottom: 15px;
        margin-bottom: 15px;

        h1 {
            font-size: 70px;
            font-family: $zenDots;
            padding-bottom: 0;
        }

        p {
            margin-top: -15px;
            font-size: 15px;
            font-weight: 600;
            color: bisque;
        }
    }

    .wordSummaryDetails {
        div {
            margin-bottom: 5px;

            span {
                font-family: $zenDots;
                color: #ffc683;
            }

            p {
                font-weight: 500;
                color: gray;
            }
        }
    }

    @media only screen and (max-width: 570px) {
        display: inline-table;
    }
}

.lastWord {
    cursor: pointer;
    transition: 0.1s ease all;

    &:hover {
        text-decoration: underline;
    }
}
