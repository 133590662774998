.loaderContainer {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px solid #111725;
    border-radius: 50%;
    border-top-color: bisque;
    width: 50px;
    height: 50px;
    -webkit-animation: rotate 5s linear infinite; /* Safari */
    animation: rotate 5s linear infinite;
    box-sizing: border-box;

    .loaderInner {
      border: 3px solid #1a2133;
      border-top-color: #ffc683;
      border-bottom-color: #ffc683;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      animation: rotate 4s linear infinite;
    }

    &__alternate {
      border-color: #111725;
      border-top-color: bisque;
    }

    &__small {
      width: 30px;
      height: 30px;
    }
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: scale(1) rotate(360deg);
  }
  50% {
    transform: scale(0.8) rotate(-360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}
