@import 'src/shared/styles/fonts.scss';

.activityGraphsDetails {
  position: relative;
  background-color: #111725;
  // position: fixed;
  // right: 0;
  // top: 0;
  // height: 100vh;
  height: 100%;
  width: 100%;
  // width: calc(100% - 180px);
  // padding: 0 20px 20px;
  // border-left: 1px solid #1a2133;
  // z-index: 10;
  // padding: 10px;
  overflow-y: scroll;
  display: block !important;

  .closeButton {
    position: fixed;
    top: 20px;
    right: 20px;
  }

  .wordGraphDetails {
    h4 {
      color: #ffc683;
      text-align: center;
    }

    .graphDetailsSummary {
      display: flex;
      // justify-content: space-between;

      .summaryColumn {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        .rewwindItem {
          color: #c8c8c8;
          padding-bottom: 15px;
        }
      }

      p {
        font-weight: 500;
        color: gray;
        padding-bottom: 5px;
      }

      .summaryValue {
        font-family: $zenDots;
        color: #ffc683;
        font-size: 30px;
      }
    }
    .period {
      color: bisque;
    }
  }

  .activityTabsContainer {
    padding: 20px 30px;
  }

  @media only screen and (max-width: 750px) {
    width: 100%;
  }
}
