@import 'tippy.js/dist/border.css';

.tooltip {
  &__nav {
    span {
      font-size: 14px;
      color: bisque;
      font-weight: 500;
    }

    &::before {
      background-color: #1d3165;
      color: #1d3165;
    }
  }
}
