.sliderContainer {
  display: flex;
  align-items: center;

  span {
    color: #3f4f7b;
    font-weight: 800;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    background: #1d3165;
    outline: none;
    transition: opacity 0.2s;
    margin-right: 10px;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ffc683;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #ffc683;
    cursor: pointer;
  }
}
