.definitionsContainer {
  h4 {
    color: bisque;
    text-transform: capitalize;
  }

  ul {
    list-style: none;

    .dateListItem {
      .dateAdded {
        font-size: 10px;
        font-weight: 400;
        border-bottom: 2px solid #1a2133;
        padding-bottom: 5px;
        display: block;
        margin-bottom: 5px;
        width: fit-content;
      }

      &.dateListItem {
        margin-bottom: 10px;
      }
    }
  }
}
