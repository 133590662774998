form {
  div {
    position: relative;
  }

  .showPasswordIcon {
    position: absolute;
    right: 0;
    width: auto;
    margin: 0;
    top: 28px;
    right: 15px;
    cursor: pointer;
    color: bisque;
  }
}
