@import 'src/shared/styles/fonts.scss';

.activityStatsContainer {
    display: grid;
    grid-gap: 30px;

    .activityStatsItem {
        padding: 20px;
        border-radius: 15px;
        border: 2px solid #111725;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        transition: 0.3s ease all;

        .backgroundImage {
            svg {
                font-size: 80px;
                opacity: 0.15;
                position: absolute;
                margin: 0;
                top: 10px;
                right: -30px;
                z-index: 0;
            }
        }

        h3 {
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
            }
        }

        .statsCount {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                font-size: 20px;
                font-family: $zenDots;
                padding-bottom: 0;
                color: #ffc683;
            }

            .statsPercentage {
                width: 40px;
                cursor: pointer;
                z-index: 1;
            }
        }

        &:hover {
            transform: translateY(-5px);
            -webkit-box-shadow: 0px 24px 22px -25px #1d3165;
            box-shadow: 0px 24px 22px -25px #1d3165;
        }
    }

    @media only screen and (max-width: 1285px) {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }

    @media only screen and (max-width: 1128px) {
        grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    }

    @media only screen and (max-width: 925px) {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }

    @media only screen and (max-width: 806px) {
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    }

    @media only screen and (max-width: 540px) {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}
