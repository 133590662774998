.moreOptionsContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: flex-end;
  // flex-direction: column;

  &__interior {
    top: 20px;
    right: 20px;
  }
}
