.activityLoaderContainer {
  margin-top: 40px;
  position: relative;
  height: 400px;
}

.activityContainer {
  display: flex;
  justify-content: center;

  .activityMain {
    width: 85%;

    .activitySummary {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 30px;
      width: 100%;
    }

    @media only screen and (max-width: 1285px) {
      width: 100%;
    }
  }

  .activityStats {
    width: 15%;
    padding-left: 30px;

    @media only screen and (max-width: 1285px) {
      width: 100%;
      padding-left: 0;
      margin-bottom: 30px;
    }
  }

  @media only screen and (min-width: 1450px) {
    width: 1400px;
    margin: auto;
  }

  @media only screen and (max-width: 1285px) {
    flex-direction: column-reverse;
  }
}
