@import './fonts.scss';
@import './sorting.scss';
@import 'react-toastify/dist/ReactToastify.css';

@import 'filepond/dist/filepond.min.css';
@import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    margin: 0;
    // background: #1a2133;
    background-color: #0f131d;
    color: #c8c8c8;
    font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        $openSans, $nunitoSans, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 13px;
}

#root {
    min-height: 100vh;
    // background-color: #0f131d;
    background-color: #111725;
    padding: 40px 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea,
select {
    border: 1px solid #1a2133;
    background: inherit;
    border-radius: 10px;
    outline: 0;
    margin: 10px 0;
    color: bisque;
    padding: 15px;
    width: 100%;
    font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        $openSans;

    &::placeholder {
        color: #fff;
        font-weight: 400;
        opacity: 0.5;
    }

    @media only screen and (max-width: 480px) {
        font-size: 16px;
    }
}

select {
    &:invalid {
        color: green !important;
    }

    [disabled] {
        color: green !important;
    }
}

textarea {
    resize: none;
    line-height: 20px;
}

select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, bisque 50%),
        linear-gradient(135deg, bisque 50%, transparent 50%),
        linear-gradient(to right, bisque, bisque);
    background-position: calc(100% - 20px) calc(1em + 10px),
        calc(100% - 15px) calc(1em + 10px), calc(100% - 2.5em) 1.1em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    margin: 0;

    select:invalid,
    option[value=''] {
        opacity: 0.5 !important;
    }
}

img {
    font-size: 10px;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding-bottom: 10px;

    &.title {
        color: bisque;
    }
}

p {
    margin: 0;
    line-height: 20px;
}

a {
    cursor: pointer;
    color: bisque !important;
    text-decoration: underline;
    transition: 0.2s ease all;

    &:hover {
        color: #ffc683;
    }
}

.icon {
    font-size: 25px;
    stroke: bisque;
    color: bisque;
    cursor: inherit;

    &.actionIcon {
        font-size: 20px;
        cursor: pointer;
        stroke: #3f4f7b;
        color: #3f4f7b;
        transition: 0.3s ease all;

        &__small {
            font-size: 17px;
            cursor: pointer;
            stroke: #3f4f7b;
            color: #3f4f7b;
            transition: 0.3s ease all;

            &:hover {
                color: #ffc683;
                stroke: #ffc683;
            }
        }

        &:hover {
            color: #ffc683;
            stroke: #ffc683;
        }
    }

    &.buttonIcon {
        font-size: 20px;
        cursor: inherit;
    }

    &.identifierIcon {
        color: #ffc683;
        stroke: #ffc683;
        vertical-align: middle;
        margin-right: 3px;
        font-size: 13px;
    }

    &.invertedIcon {
        transform: scaleX(-1);
    }

    &.disabled {
        opacity: 0.4;
        cursor: not-allowed;

        &:hover {
            color: #3f4f7b;
            stroke: #3f4f7b;
        }
    }
}

.dangerIcon {
    stroke: rgb(163, 76, 76) !important;
    color: rgb(163, 76, 76) !important;

    &:hover {
        color: red !important;
        stroke: red !important;
    }
}

.tag {
    padding: 1px 7px 3px;
    display: block;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 10px;
    border: 1px solid #3f4f7b;
    color: #3f4f7b;
    font-size: 13px;
    margin-left: auto;
    text-transform: lowercase;
    display: flex;
    align-items: center;
}

.word {
    background-color: #111725;
    color: #3f4f7b;
    border: 1px solid #3f4f7b;
    font-weight: 500;
    border-radius: 15px;
    padding: 3px 10px 5px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.Toastify__toast {
    background: #1a2133;
    font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        $openSans;
    color: bisque;
    border-radius: 35px;
    min-height: unset;
    font-weight: 600;

    .Toastify__toast-body {
        padding: 6px 12px;
    }

    &--default {
        -webkit-box-shadow: 0px 20px 50px -15px #1d3165;
        box-shadow: 0px 20px 50px -15px #1d3165;
    }

    &--success {
        -webkit-box-shadow: 0px 20px 50px -15px green;
        box-shadow: 0px 20px 50px -15px green;
    }

    &--error {
        -webkit-box-shadow: 0px 20px 50px -15px red;
        box-shadow: 0px 20px 50px -15px red;
    }

    button {
        color: bisque;
        opacity: 1;
        background: #1d3165;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: none;
    }
}

.Toastify__toast-container {
    z-index: 9999999;
    max-width: 320px;
    width: unset;

    @media only screen and (max-width: 750px) {
        left: 20px;
        bottom: 20px;
    }
}

.Toastify__progress-bar--default {
    background-color: bisque;
}

.filepond--root {
    width: 150px;
    font-size: 13px !important;

    .filepond-drop-label {
        height: inherit;
    }
}

.__react_component_tooltip {
    border-radius: 8px !important;
    padding: 3px 10px 5px !important;
    font-weight: 400 !important;
    z-index: 999999 !important;
    background: bisque !important;
    color: #1a2133 !important;

    &::after {
        border-top-color: bisque !important;
    }
}

.tippy-box {
    border-radius: 10px !important;
}

.tippy-content {
    font-size: 11px;
}

.emoji {
    font-size: 20px !important;
    vertical-align: middle;
}

.notice {
    font-size: 11px;
    font-weight: 500;
    width: 100%;
    display: block;
    text-align: right;
    padding-top: 5px;
    color: #3f4f7b;

    &__left {
        text-align: left;
    }
}

.highlight {
    color: inherit;
    text-decoration: underline;
    background-color: inherit;
    font-weight: 700;
}

.example {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Open Sans',
        sans-serif;
    font-size: 13px;
    font-weight: 100;
}

.partOfSpeech {
    font-size: 10px;
    font-weight: 300;
}

.modalFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;

    button {
        margin: 0 5px;
    }
}
