.dropdown {
  position: absolute;
  padding: 5px 0;
  background-color: #1d3165;
  top: 32px;
  right: 0;
  border-radius: 10px;
  width: 130px;
  -webkit-box-shadow: 0px 24px 22px -25px #1d3165;
  box-shadow: 0px 24px 22px -25px #1d3165;
  height: fit-content;
  z-index: 999999;

  &__regular {
    top: 50px;
  }

  ul {
    .option {
      transition: 0.2s ease all;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px 15px;
      font-size: 12px;
      font-weight: 500;
      font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Open Sans', sans-serif;
      color: #c8c8c8;

      svg {
        stroke: #c8c8c8;
        color: #c8c8c8;
        transition: 0.2s ease all;
        font-size: 15px;
        margin-right: 7px;
      }

      &:last-of-type {
        border: 0;
      }

      &:hover {
        color: #ffc683;
        background: radial-gradient(#1a2133, transparent);

        svg {
          stroke: #ffc683;
          color: #ffc683;
        }
      }

      &__disabled {
        color: #555;
        cursor: not-allowed;

        svg {
          stroke: #555;
          color: #555;
        }

        &:hover {
          color: #555;

          svg {
            color: #555 !important;
            stroke: #555 !important;
          }
        }
      }

      &__danger {
        color: rgb(163, 76, 76);

        &:hover {
          color: red;

          svg {
            color: red !important;
            stroke: red !important;
          }
        }
      }
    }
  }
}
