.notesListContainer {
    // margin-left: 20px;

    .notesSortContainer {
        position: sticky;
        top: 65px;
        z-index: 99;
        // margin-top: 20px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 855px) {
            top: 0;
            // padding-top: 20px;
            height: 70px;
        }

        @media only screen and (max-width: 750px) {
            top: 60px;
        }
    }

    .notesList {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        height: 100%;
        margin-top: 20px;
        grid-gap: 20px;
        gap: 20px;

        @media only screen and (max-width: 855px) {
            flex-direction: column;
        }

        &__grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            overflow-x: unset;
        }
    }
}
