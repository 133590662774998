.collectionsContainer {
  position: relative;
  // height: 100%;
  padding-bottom: 20px;

  .collectionsLoaderContainer {
    margin-top: 40px;
    position: relative;
    height: 400px;
  }

  .collectionsRenderRight {
    @media only screen and (max-width: 547px) {
      position: fixed;
      right: 20px;
      top: 14px;
      z-index: 9999;
    }
  }

  .collections {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
  }
}
