.editCollectionContainer {
  .editCollectionSection {
    padding: 10px 0;

    input,
    textarea {
      margin: 0;
    }
  }

  .imageContainer {
    border-top: 1px solid #1a2133;
    padding-top: 20px;
    margin-top: 20px;
    display: block;
    margin-bottom: 40px;

    .filepond--root {
      width: 100%;
      font-size: 13px !important;
      border-radius: 15px;
      margin-bottom: 40px;
    }

    .filepond--drop-label {
      display: block;
      left: 10px;
      top: 5px;
      right: unset;

      label {
        color: #fff;
        font-weight: 400;
        opacity: 0.5;
        font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI',
          'Open Sans', sans-serif;
      }
    }

    .filepond--panel-root {
      background-color: #1a2133 !important;
    }
  }
}
