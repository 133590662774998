@import 'shared/styles/fonts.scss';

.sectionHeaderContainer {
  display: flex;

  .titleContainer {
    width: 100%;
    margin-right: 40px;

    .title {
      padding: 0;
      display: flex;
      color: #ffc683;

      &__word {
        font-family: $zenDots;
        display: flex;
        align-items: center;
      }
    }

    .subTitle {
      color: gray;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .renderRight {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 750px) {
    &__pageTitle {
      margin-left: 40px;
    }
  }
}
