.selectorItem {
  padding: 10px 0;
  border-bottom: 1px solid #1a2133;
  transition: 0.3s ease all;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-of-type {
    border-bottom: 0;
  }

  .selectorDetails {
    width: 70%;
    opacity: 0.5;

    &__selected {
      opacity: 1;
    }

    h4 {
      padding: 0;
      font-weight: 400;
    }

    p {
      margin-top: 3px;
      font-weight: 300;
      font-size: 11px;
      margin-top: 3px;

      span {
        color: bisque;
      }
    }
  }
}
