.wordsContainer {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;

  .words {
    display: flex;
    flex-direction: column;
    width: 100%;

    .wordsRenderRight {
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        transition: 0.3s ease all;
      }

      @media only screen and (max-width: 547px) {
        position: fixed;
        right: 20px;
        z-index: 100;
      }
    }

    .wordsDetails {
      position: relative;
      height: 100%;
      transition: 0.3s ease all;
    }
  }
}
