@import 'shared/styles/fonts.scss';

.wordListItem {
    padding: 0 15px 15px 15px;
    border: 2px solid #111725;
    // background-color: #111725;
    border-radius: 15px;
    position: relative;
    transition: 0.3s ease all;
    cursor: pointer;

    &__selected {
        border-color: #3f4f7b;
    }

    &:hover {
        -webkit-box-shadow: 0px 24px 22px -25px #1d3165;
        box-shadow: 0px 24px 22px -25px #1d3165;
    }

    .wordListItemHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;

        h3 {
            font-family: $zenDots;
            font-size: 13px;
            font-weight: 400;
            padding-bottom: 0;
            display: flex;

            span {
                display: flex;
            }
        }
    }

    p {
        font-weight: 400;
        font-size: 13px;
        margin-bottom: 5px;
        line-height: 20px;
    }

    .wordListDetails {
        border-bottom: 1px solid #1a2133;
        padding: 20px 0;

        &:last-of-type {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    .listItemControls {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .partOfSpeechIdentifier {
            font-size: 10px;
            font-weight: 300;
        }

        .macros {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            flex-grow: 1;
        }
    }

    .wordListItemFooter {
        position: absolute;
        bottom: -10px;
        left: -10px;
        border-radius: 50%;
        border-left: 0;
        border-bottom: 0;
        font-size: 12px;
        padding: 5px;
        font-weight: 700;
        color: #1a2133;
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: bisque;
    }
}
