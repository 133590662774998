.imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    padding-bottom: 10px;

    .filepond--drop-label {
        cursor: pointer;

        label {
            color: #fff;
            font-weight: 400;
            opacity: 0.5;
            font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI',
                'Open Sans', sans-serif;
            cursor: pointer;
        }
    }

    .filepond--panel-root {
        background-color: #1a2133 !important;
    }
}

form {
    margin-bottom: 20px;
}

.infoContainer {
    padding-top: 10px;
}
