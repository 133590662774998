@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800&family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Leckerli+One&display=swap');

$poppins: 'Poppins', sans-serif;
$nunitoSans: 'Nunito Sans', sans-serif;
$openSans: 'Open Sans', sans-serif;
$monoton: 'Monoton', cursive;
$zenDots: 'Zen Dots', cursive;
$MontAlt: 'Montserrat Alternates', sans-serif;
$Leckerli: 'Leckerli One', cursive;
