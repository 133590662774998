.searchLoaderContainer {
  position: relative;
  height: 400px;
}

.searchResultContainer {
  width: 60%;
  padding: 0 20px 20px 20px;
  height: calc(100vh - 120px);
  overflow-y: auto;

  .searchResultHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    background-color: #0f131d;
    position: sticky;
    top: 0;
    z-index: 1;

    h3 {
      display: flex;
      align-items: center;
      color: bisque;
      font-weight: 400;

      span {
        margin-left: 5px;
        color: #ffc683;
        font-weight: 600;
      }

      &::before {
        content: '';
        border-bottom: 1px solid bisque;
        width: 15px;
        margin-right: 10px;
      }
    }
  }

  .searchItemContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
    margin-top: 20px;
  }

  .searchTitle {
    margin-bottom: 20px;
    border: 0;
  }

  @media only screen and (max-width: 750px) {
    width: 100%;
    padding: 0;
  }
}
