.notVerifiedContainer {
  color: #3f4f7b;
  padding: 40px 40px 0;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  svg {
    font-size: 80px;
  }

  .sendVerificationEmailButton {
    width: 80%;
    margin: 20px auto;
  }

  .goToSettingsLink {
    margin-top: 10px;
  }

  @media only screen and (max-width: 745px) {
    padding: 80px 0 0;
  }
}

.sendVerificationEmailLink,
.goToSettingsLink {
  text-decoration: underline;
  font-weight: 600;
  display: flex;
}
