.editWordContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > div {
    width: 100%;

    .editWordControls {
      display: flex;
      justify-content: flex-end;
      gap: 5px;

      .editWordControlsButtons {
        display: flex;
        align-items: center;
        justify-self: center;

        button {
          &:first-of-type {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
