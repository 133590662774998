.notesLoaderContainer {
    margin-top: 40px;
    position: relative;
    height: 400px;
}

.notesWrapper {
    display: flex;
    // margin-top: 20px;

    .notesBody {
        // width: calc(100% - 450px);
        width: 100%;
        // margin-left: 450px;
        transition: 0.3s ease all;

        &__expanded {
            width: calc(100% - 70px);
            margin-left: 60px;

            @media only screen and (max-width: 450px) {
                width: calc(100% - 50px);
                margin-left: 50px;
            }
        }

        // @media only screen and (max-width: 750px) {
        //     width: calc(100% - 30px);
        //     margin-left: 30px;
        // }
    }
}
