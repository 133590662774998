.noDataContainer {
  color: #3f4f7b;
  text-align: center;
  width: 100%;
  padding: 40px;

  svg {
    font-size: 80px;
  }

  h1 {
    padding-bottom: 0;
  }
}
