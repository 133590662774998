.noteContainer {
    // width: 450px;
    width: 100%;

    padding: 20px 0;
    border-radius: 20px;
    height: 100%;
    // position: absolute;
    transition: 0.3s ease all;
    bottom: 0;
    background-color: #0f131d;
    z-index: 999;

    form {
        height: 100%;

        .formHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            .formControls {
                display: flex;
                align-items: center;

                .subControls {
                    margin-left: 10px;
                    padding-left: 10px;
                    display: flex;
                    align-items: center;
                    border-left: 1px solid #1a2133;
                }
            }

            .formHeaderRight {
                display: flex;
                align-items: center;

                svg {
                    margin-left: 10px;
                }
            }

            .saveButtonControls {
                img {
                    height: 40px;
                }
            }

            h3 {
                @media only screen and (max-width: 750px) {
                    margin-left: 10px;
                }
            }

            // @media only screen and (max-width: 750px) {
            //     justify-content: flex-end;

            //     .saveButtonControls {
            //         margin-right: 10px;
            //     }
            // }
        }

        .noteTitle {
            input {
                font-size: 20px;
                font-weight: 600;
                border: 0;
                background-color: inherit;
                border-radius: 0;
                padding-left: 0;
                padding-top: 0;
                padding-right: 0;
                margin-top: 0;
            }
        }

        .noteContent {
            height: calc(100% - 110px);

            .quill {
                .ql-toolbar {
                    border: 0;
                    margin-left: -15px;
                    font-family: 'Avenir', -apple-system, BlinkMacSystemFont,
                        'Segoe UI', 'Open Sans', sans-serif;

                    button {
                        &:hover {
                            color: bisque;
                        }
                    }

                    .ql-formats {
                    }

                    &.ql-snow .ql-formats {
                        margin-right: 10px;
                    }
                }

                .ql-container,
                .ql-editor {
                    height: auto !important;
                    font-size: 16px;

                    ul,
                    ol {
                        padding-left: 0;
                    }
                }

                .ql-container {
                    border: 0;
                    height: 100%;
                    margin-left: -15px;
                    font-family: 'Avenir', -apple-system, BlinkMacSystemFont,
                        'Segoe UI', 'Open Sans', sans-serif;
                    min-height: 100px !important;
                    max-height: 350px;
                    overflow: hidden;
                    overflow-y: scroll;
                    overflow-x: scroll;

                    .ql-editor.ql-blank {
                        &::before {
                            color: #fff;
                            font-weight: 400;
                            opacity: 0.5;
                            font-style: normal;
                        }
                    }

                    .ql-tooltip.ql-snow,
                    .ql-tooltip.ql-editing {
                        background-color: #1d3165;
                        // background-color: #0f131d;
                        border: 1px solid #1a2133;
                        border-radius: 20px;
                        box-shadow: none;
                        padding: 5px 20px;

                        input {
                            border: 0;
                            padding-left: 10px;
                        }

                        .ql-action,
                        .ql-remove {
                            color: #ffc683;

                            &:hover {
                                color: #d9a467;
                            }
                        }

                        &::before {
                            color: #f5f5f5;
                        }
                    }

                    &.ql-bubble {
                        .ql-toolbar {
                            .ql-formats {
                                margin: 0;

                                &:first-child {
                                    margin-left: 5px;
                                }
                            }
                        }

                        // .ql-tooltip {
                        //     &:not(.ql-flip) {
                        //         .ql-tooltip-arrow {
                        //             // border-bottom: 6px solid #444;
                        //             // display: none;
                        //         }
                        //     }
                        // }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 855px) {
        // position: fixed;
        // height: calc(100% - 90px);
    }

    @media only screen and (max-width: 750px) {
        // height: 100%;
        // width: 100%;
        // margin-left: -30px;
    }

    // This media query needs to come before &__closed
    @media only screen and (max-width: 450px) {
        height: 100%;
        padding-right: 10px;
        width: calc(100% - 20px);
    }

    &__maximized {
        width: 100%;
    }

    &__closed {
        transform: translateX(-90%);
        background-color: unset;

        form {
            .noteTitle {
                input {
                    border-bottom: 0;
                }
            }
        }

        @media only screen and (min-width: 750px) {
            transform: translateX(-92%);
        }

        @media only screen and (max-width: 749px) {
            transform: translateX(-92%);
            // height: 90%;
        }

        @media only screen and (max-width: 450px) {
            transform: translateX(-88%);
        }
    }
}
