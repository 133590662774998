@import 'src/shared/styles/fonts.scss';

.recentlyAddedContainer {
    padding: 20px 25px;
    border-radius: 15px;
    position: relative;
    border: 2px solid #111725;

    h3 {
        border-bottom: 1px solid #1a2133;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }

    .stats {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        div {
            margin-bottom: 5px;
            width: 50%;

            p {
                color: gray;
                font-weight: 500;
            }

            span {
                font-family: $zenDots;
                color: #ffc683;
            }
        }
    }

    .recentlyAddedWords {
        display: flex;
        flex-wrap: wrap;
    }
}
