@import '../../styles/fonts.scss';

button {
  color: #c8c8c8;
  font-weight: 600;
  font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    $openSans;
  background-color: #1d3165;
  padding: 15px 15px;
  border-radius: 15px;
  position: relative;
  border: 0;
  width: 100%;
  cursor: pointer;
  margin: 10px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease all;

  .svgLoader {
    height: 50px;
    position: absolute;
    margin-left: 20px;
  }

  span {
    display: flex;
  }

  &:hover {
    background-color: #253c7b;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #192650;
    transition: 0.2s ease all;

    &:hover {
      background-color: #192650;
    }

    span {
      opacity: 0.7;
    }
  }
}

.buttonCategory {
  &__icon {
    margin: 0;
    border-radius: inherit;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    outline: 0;
    transition: 0.2s ease all;

    &:hover {
      background-color: #253c7b;
    }

    &:active {
      transform: scale(0.9);
    }

    &__small {
      height: 25px;
      width: 25px;
      padding: 0;

      svg {
        font-size: 15px !important;
      }
    }

    &__alternate {
      margin: 0;
      border-radius: inherit;
      height: 40px;
      width: 40px;
      padding: 0;
      border-radius: 50%;
      outline: 0;
      transition: 0.2s ease all;
      background-color: #ffc683;

      svg {
        stroke: #1a2133;
      }

      &__small {
        height: 24px;
        width: 24px;
        padding: 0;

        svg {
          font-size: 15px !important;
          fill: #1a2133;
        }
      }

      &:disabled {
        cursor: not-allowed;
        background-color: #856540;
        transition: 0.2s ease all;

        &:hover {
          background-color: #856540;
        }

        span {
          opacity: 0.7;
        }
      }

      &:hover {
        background-color: #d9a467;
      }

      &:active {
        transform: scale(0.9);
      }
    }
  }

  &__link {
    background-color: inherit;
    padding: 0;
    width: fit-content;
    padding-right: 15px;

    &:hover {
      background-color: inherit;
      text-decoration: underline;
    }
  }

  &__secondary {
    background-color: #ffc683;
    color: #1a2133;
    border-radius: 25px;
    padding: 10px 12px;

    span {
      display: flex;
    }

    svg {
      stroke: #1a2133;
      font-size: 15px !important;
    }

    &:hover {
      background-color: #d9a467;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: #856540;
      transition: 0.2s ease all;

      &:hover {
        background-color: #856540;
      }

      span {
        opacity: 0.7;
      }
    }
  }
}
