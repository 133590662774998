.navContainer {
    // position: fixed;
    // padding: 20px 0;
    // background-color: #0f131d;
    // display: flex;
    // z-index: 9999;
    // height: 100vh;
    // align-items: center;
    // flex-direction: column;
    // width: 80px;
    // justify-content: space-between;
    // border-right: 1px solid #1a2133;

    padding: 20px 30px;
    position: fixed;
    // padding: 5px 0;
    // background-color: #111725;
    display: flex;
    // z-index: 9999;
    height: calc(100vh - 40px);
    align-items: flex-start;
    flex-direction: column;
    width: 170px;
    justify-content: space-between;
    // border-radius: 50px;

    .mainNav {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        list-style: none;
        width: 100%;

        li {
            text-align: center;
            margin: 10px 0;

            .navText {
                font-size: 15px;
                color: bisque;
                font-weight: 700;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                font-size: 13px;
                color: #303a52;
                font-weight: 500;
                border-radius: 15px;
                padding: 5px 10px;
                transition: 0.1s ease all;
                position: relative;
                // margin: 0 20px;

                svg {
                    transition: 0.1s ease all;
                }

                .newIndicator {
                    font-size: 10px;
                    background: #b92b27;
                    background: linear-gradient(to right, #1565c0, #b92b27);
                    padding: 0px 5px 1px;
                    border-radius: 15px;
                    color: #c8c8c8;
                    position: absolute;
                    top: -5px;
                    right: -10px;
                }

                .navText {
                    margin-left: 10px;
                    font-size: 16px;
                    color: #3f4f7b;
                    transition: 0.1s ease all;
                }

                .navigationCount {
                    background: #1d3165;
                    position: absolute;
                    color: #ffc683;
                    right: -18px;
                    border-radius: 10px;
                    font-size: 12px;
                    font-weight: 600;
                    padding: 3px 4px;
                    min-width: 25px;
                    border: 2px solid #111725;
                }

                &:hover {
                    border-color: #ffc683;
                    color: #ffc683;

                    svg {
                        stroke: #ffc683;
                    }

                    .navText {
                        color: #ffc683;
                    }
                }
            }

            .active {
                background-color: #ffc683;
                color: #1a2133;
                border-color: #ffc683;

                svg {
                    stroke: #1a2133 !important;
                    color: #1a2133 !important;
                }

                .navText {
                    color: #1a2133;
                }

                &:hover {
                    border-color: #ffc683;
                    color: #1a2133;

                    svg {
                        stroke: #1a2133 !important;
                        color: #1a2133 !important;
                    }

                    .navText {
                        color: #1a2133;
                    }
                }
            }
        }
    }

    .bottomNav {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        list-style: none;
        width: 100%;

        li {
            position: relative;
            margin: 10px auto;
            width: 100%;

            .profileMenu {
                position: absolute;
                left: 30px;
                bottom: 20px;
                background-color: #1d3165;
                border-radius: 10px;
                width: 180px;

                ul {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 10px 0;
                    border-bottom: 2px solid #1a2133;
                    list-style: none;

                    li {
                        font-size: 20px;
                        width: 100%;
                        text-align: center;

                        a {
                            display: flex;
                            align-items: center;
                            width: inherit;
                            border-right: 2px solid #1d3165;
                            border-left: 2px solid #1d3165;
                            text-decoration: none;
                            padding: 0 20px;
                            transition: 0.1s ease all;
                            border-radius: unset;
                            background-color: unset;

                            svg {
                                stroke: bisque !important;
                                color: bisque;
                                transition: 0.1s ease all;
                                margin-right: 7px;
                            }

                            img {
                                margin-right: 7px;
                            }

                            span {
                                font-size: 15px;
                                font-weight: 500;
                                color: bisque;
                                display: flex;
                                align-items: center;
                            }

                            &.active {
                                img {
                                    border: 3px solid #ffc683;
                                    transition: 0.1s ease all;
                                }

                                svg {
                                    stroke: #ffc683 !important;
                                    transition: 0.1s ease all;
                                }

                                span {
                                    color: #ffc683;
                                }
                            }

                            &:hover {
                                border-left-color: #3f4f7b;
                            }
                        }
                    }
                }

                &__footer {
                    font-size: 9px;
                    display: block;
                    text-align: center;
                    padding: 5px 0;
                }
            }
        }

        a {
            .profilePicture {
                transition: 0.2s ease all;
                height: 35px;
                width: 35px;
                border-radius: 12px;
                object-fit: cover;

                &__small {
                    height: 30px;
                    width: 30px;
                    border-radius: 8px;
                    object-fit: cover;
                }

                &:hover {
                    border: 3px solid #ffc683;
                }
            }

            &.active {
                background-color: #ffc683;
                width: 47px;
                transition: 0.1s ease all;
            }

            svg {
                &:hover {
                    stroke: #ffc683;
                    color: #ffc683;
                }
            }
        }
    }

    @media only screen and (max-width: 750px) {
        display: none;
    }
}
