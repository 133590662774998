.mobileNavContainer {
    position: fixed;
    left: 10px;
    top: 10px;
    z-index: 999999;

    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #1d3165;
        border-radius: 10px;
        margin-top: 10px;
        padding: 20px 0;

        li {
            font-size: 20px;
            width: 100%;
            text-align: center;

            &:not(:last-of-type) {
                margin-bottom: 15px;
            }

            a {
                display: flex;
                align-items: center;
                width: inherit;
                border-right: 2px solid #1d3165;
                border-left: 2px solid #1d3165;
                text-decoration: none;
                padding: 0 20px;
                transition: 0.1s ease all;

                svg {
                    stroke: bisque !important;
                    color: bisque !important;
                    transition: 0.1s ease all;
                }

                .profile-picture {
                    height: 28px;
                    width: 28px;
                    border-radius: 10px;
                    object-fit: cover;
                }

                span {
                    font-size: 15px;
                    font-weight: 500;
                    color: bisque;
                    margin-left: 7px;
                }

                .newIndicator {
                    font-size: 10px;
                    background: #b92b27;
                    background: linear-gradient(to right, #1565c0, #b92b27);
                    padding: 0px 5px 1px;
                    border-radius: 15px;
                    color: #c8c8c8;
                }

                &:hover {
                    border-left-color: #3f4f7b;
                }
            }

            .active {
                border-left-color: #ffc683;
                transition: 0.1s ease all;

                svg {
                    stroke: #ffc683 !important;
                    color: #ffc683 !important;
                }

                span {
                    color: #ffc683;
                }

                &:hover {
                    border-left-color: #ffc683;
                }
            }

            .menuFooter {
                font-size: 9px;
                display: block;
                text-align: center;
                padding: 0 20px;
                margin-top: -10px;
                margin-bottom: -15px;

                a {
                    display: unset;
                    padding: unset;
                    text-decoration: underline;
                    border-left: 0;
                }
            }
        }

        hr {
            width: 100%;
            border: 0;
            border-bottom: 1px solid #1a2133;
            margin-bottom: 15px;
        }
    }

    @media only screen and (min-width: 751px) {
        display: none;
    }
}

.mobileNavigationClass {
    width: fit-content;
    padding: 0;
    border-radius: 25px;

    span {
        padding: 6px;
        color: bisque;
        font-size: 14px;
    }

    .divider {
        padding: 2px 5px;
        margin: -8px 0;

        hr {
            border: 1px solid #0f131d;
        }
    }
}
