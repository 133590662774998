.wordSettingsContainer {
  .wordSettingsItem {
    padding-bottom: 10px;
    border-bottom: 1px solid #1a2133;

    .wordSettingsItemHeader {
      cursor: pointer;

      span {
        position: absolute;
        right: 0;
      }
    }

    &:last-of-type {
      border-bottom: 0;
    }

    .wordSettingsBody {
      margin-top: 15px;
    }
  }
}
