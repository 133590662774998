.searchListContainer {
  width: 40%;
  height: calc(100vh - 120px);
  overflow-y: auto;
  border-right: 1px solid #111725;
  margin-right: 20px;

  .searchList {
    position: relative;
    justify-content: space-around;
    border-radius: inherit;
    overflow-y: auto;

    ul {
      max-height: 190px;
      overflow-y: scroll;
      padding-bottom: 20px;

      li {
        transition: 0.2s ease all;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px 0;
        font-size: 12px;
        transition: 0.2s ease all;
        border: 0;

        &:hover {
          color: #ffc683;
        }
      }
    }

    .userWords,
    .newWords {
      ul {
        display: flex;
        flex-wrap: wrap;
        height: auto;

        li {
          padding: 3px 10px 5px;
        }
      }
    }

    .references {
      ul {
        li {
          margin-bottom: 10px;
          padding-top: 0;
          padding-left: 0;
          padding-right: 0;

          span {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .newWords,
    .userWords {
      .noResults {
        text-align: center;
      }

      @media only screen and (max-width: 750px) {
        width: 100%;
      }
    }

    .userWords,
    .newWords,
    .references {
      position: relative;
      padding: 20px 20px 0 0;

      h3 {
        color: bisque;
        display: flex;
        align-items: center;
        font-weight: 400;

        svg {
          margin-right: 3px;
        }

        &::before {
          content: '';
          border-bottom: 1px solid bisque;
          width: 15px;
          margin-right: 10px;
        }
      }
    }

    @media only screen and (max-width: 750px) {
      display: block;
    }
  }

  @media only screen and (max-width: 750px) {
    width: 100%;
    margin-right: 0;
    height: calc(100vh - 180px);
    border-right: 0;
  }

  @media only screen and (max-width: 547px) {
    top: 105px;
  }
}
