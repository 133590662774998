.graphContainer {
    padding: 20px 0px 5px;
    border: 1px solid;
    border-radius: 15px;
    border: 2px solid #111725;
    width: 100%;

    .graphTooltip {
        background-color: #1a2133;
        padding: 10px 15px 0;
        border-radius: 15px;

        .tooltipTitle {
            font-weight: 600;
            font-size: 12px;
        }

        .tooltipSubTitle {
            color: gray;
            font-size: 10px;
            margin: 7px -15px 0;
            padding: 5px 15px 5px;
            // border-top: 1px solid #111725;
            // text-align: right;
        }
    }
}
