.selectedIndicator {
    right: 0;
    position: absolute;
    top: -21px;
    display: inline-block;
    background-color: bisque;
    color: #1a2133;
    font-weight: 600;
    font-size: 11px;
    border-radius: 0 0 10px 10px;
    padding: 2px 10px;
}

.selectorSearch {
    position: relative;

    input {
        margin: 0;
        height: 40px;
    }

    svg {
        position: absolute;
        top: 20px;
        right: 10px;
    }
}

.selectorItems {
    max-height: calc(100% - 180px);
    overflow-y: scroll;
    margin-top: 20px;
    border-top: 2px solid #1a2133;

    .searchWord {
        text-align: center;
        padding: 10px 0;
        cursor: pointer;
        transition: 0.3s ease all;

        span {
            color: bisque;
            font-weight: 600;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    @media only screen and (max-width: 750px) {
        max-height: calc(100% - 160px);
    }
}
