.settingsContainer {
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    border: 2px solid #111725;
    border-radius: 20px;

    + .settingsItem {
        :first-child {
            background-color: red;
        }
    }

    .settingsItem {
        padding: 15px 0;

        .settingsControls {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
                padding: 0;
                font-weight: 400;

                .newIndicator {
                    font-size: 10px;
                    background: #b92b27;
                    background: linear-gradient(to right, #1565c0, #b92b27);
                    padding: 0px 5px 1px;
                    border-radius: 15px;
                    color: #c8c8c8;
                    margin-left: 5px;
                }
            }

            .voicePicker {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 10px;
                }
            }

            @media only screen and (max-width: 750px) {
                display: flex;
                margin-left: 0;
                flex-direction: column;
                align-items: flex-start;

                &:first-of-type {
                    padding-top: 0;
                }

                h4 {
                    margin-bottom: 10px;
                }

                > div {
                    margin-bottom: 10px;
                }
            }
        }

        &:first-of-type {
            padding-top: 0;
        }
    }

    .formControls {
        margin-top: 25px;
        padding-top: 15px;
        border-top: 1px solid #1a2133;
    }

    @media only screen and (min-width: 1024px) {
        width: 70%;
    }

    @media only screen and (max-width: 750px) {
        // padding: 0 10px;
    }
}
