@import './fonts.scss';

.sortContainer {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 40px;
  background-color: #0f131d;
  border-radius: 15px;
  margin-right: 5px;

  .sortType {
    display: flex;
    align-items: center;
    width: fit-content;
    height: fit-content;
    border: 1px solid #1d3165;
    border-radius: 14px;
    padding: 3px;
    opacity: 0.5;
    position: relative;
    margin: 0 3px;
    transition: 0.3s ease all;

    .sortItem {
      display: flex;
      align-items: center;
      background-color: #1d3165;
      border-radius: 10px;
      border: 1px solid #1d3165;
      padding: 3px;
      cursor: pointer;
      opacity: 0.5;
      transition: 0.1s ease all;

      svg {
        font-size: 17px;
        color: bisque;
      }

      &__active {
        opacity: 1;
      }

      &:not(:last-of-type) {
        margin-right: 5px;
      }
    }

    .archivedCount {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: #1d3165;
      right: -13px;
      top: -13px;
      font-size: 10px;
      font-weight: 600;
      padding: 4px;
      min-width: 25px;
      border: 2px solid #0f131d;
      border-radius: 10px;
      width: 10px;
      color: bisque;
    }

    &:last-of-type {
      // margin-right: 0px;
    }

    &__active {
      opacity: 1;
    }
  }
}
