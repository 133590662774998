.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(11 15 24 / 95%);
  z-index: 999999 !important;
  font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Open Sans', sans-serif;

  .modal {
    position: relative;
    margin: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
    max-width: 650px;
    width: 500px;
    max-height: 650px;
    background-color: #111725;
    display: block;
    border-radius: 20px;
    color: #fff;
    transition: all 0.5s ease-in-out;
    padding: 25px 30px;

    .modalHeader {
      margin-bottom: 20px;
    }

    .modalBody {
      max-height: 500px;
      min-height: 100px;
      overflow-y: scroll;
      background-color: inherit;

      > svg {
        font-size: 50px;
        margin-bottom: 10px;
      }

      > p {
        color: #b3b3b3;
        margin-bottom: 10px;
      }
    }

    @media only screen and (max-width: 750px) {
      // width: 400px;
    }

    @media only screen and (max-width: 750px) {
      position: fixed;
      transform: none;
      left: 0;
      top: auto;
      bottom: 0;
      border-radius: 20px 20px 0px 0px;
      width: 100% !important;
      max-width: 100%;
    }
  }
}
