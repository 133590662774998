.wordDetails {
  height: 100%;
  background-color: inherit;

  .wordSideBarOverview {
    margin-bottom: 20px;

    p {
      font-weight: 600;

      span {
        color: bisque;
      }
    }
  }

  @media only screen and (max-width: 480px) {
    height: calc(100% - 60px);
    padding-bottom: 20px;
  }
}
