.pill {
  background-color: #111725;
  color: #3f4f7b;
  border: 1px solid #3f4f7b;
  font-weight: 500;
  border-radius: 15px;
  padding: 4px 10px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
  display: inline-flex;

  &.wordPill {
    cursor: pointer;
    transition: 0.2s ease all;

    &:hover {
      border-color: #ffc683;
      color: #ffc683;
    }
  }
}
