form {
  .emailHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    h3 {
      padding: 0;
    }

    .verificationPill {
      font-size: 11px;
      border-radius: 10px;
      color: #c8c8c8;
      padding: 3px 10px;

      &__verified {
        background-color: rgb(64, 107, 64);
      }

      &__unverified {
        background-color: rgb(163, 76, 76);
      }
    }
  }
}
