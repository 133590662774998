.referenceContainer {
  padding-bottom: 20px;

  .referenceHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h4 {
    text-transform: capitalize;
    color: bisque;
  }

  ul {
    list-style: none;

    li {
      .referenceList {
        .wordReferencesListItem {
          padding-bottom: 10px;
          position: relative;
        }
      }

      .dateAdded {
        font-size: 10px;
        font-weight: 400;
        border-bottom: 2px solid #1a2133;
        padding-bottom: 5px;
        display: block;
        margin-bottom: 5px;
        width: fit-content;
      }

      .reference {
        display: flex;
        justify-content: space-between;
        position: relative;

        .referenceDetails {
          font-weight: 400;
          transition: 0.2s ease all;

          &__blurred {
            opacity: 0.2;
          }
        }

        .editContainer {
          text-align: right;
          position: absolute;
          background-color: #0f131d;
          border: 1px solid #3f4f7b;
          padding: 4px 7px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 15px;
          margin: 0;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          top: 50%;

          svg {
            margin: 0 3px;
          }
        }
      }

      &.dateListItem {
        margin-bottom: 10px;
      }
    }
  }

  .noReference {
    font-weight: 300;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
