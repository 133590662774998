.collectionContainer {
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    .detailItem {
        margin-bottom: 20px;

        label {
            display: block;
            font-weight: 300;
            font-size: 11px;
            margin-bottom: 3px;
            color: #c8c8c8;

            span {
                font-size: 13px;
                color: bisque;
                font-weight: 600;
            }
        }

        h3 {
            padding: 0;
            color: bisque;
            margin-right: 10px;
            padding: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        p {
            font-size: 13px;
            font-weight: 500;
            color: #c8c8c8;
        }
    }

    .collectionDetails {
        position: sticky;
        top: 76px;
        width: 250px;
        height: fit-content;
        padding: 20px;
        transition: 0.3s ease all;
        position: sticky;
        border: 2px solid #111725;
        border-radius: 15px;

        .collectionImageContainer {
            border-bottom: 10px solid #1a2133;
            border-radius: 14px 14px 0 0;
            background-size: cover;
            background-position: center;
            opacity: 0.75;
            height: 60px;
            margin: -20px -20px 20px;

            @media only screen and (max-width: 547px) {
                border-radius: 0;
            }
        }

        .viewMoreContainer {
            text-align: center;
            margin-top: 20px;

            svg {
                cursor: pointer;
            }
        }

        @media only screen and (max-width: 547px) {
            width: 100vw;
            top: 0;
            border-radius: 0 0 15px 15px;
            z-index: 999;
            margin: 0 -20px;
        }
    }

    .collectionWords {
        width: 100%;
        margin-left: 20px;

        @media only screen and (max-width: 547px) {
            margin-left: 0;
            margin-top: 20px;
        }
    }

    .collectionMoreOptions {
        position: absolute;
        right: 20px;
    }

    @media only screen and (max-width: 547px) {
        flex-direction: column;
        margin-top: 0;
    }
}
