.signInContainer {
  margin-top: 80px;

  form {
    div {
      position: relative;
    }
  }

  p {
    padding-top: 10px;
    font-size: 11px;
    text-align: center;

    a {
      text-decoration: underline;
    }
  }

  .showPasswordIcon {
    position: absolute;
    right: 0;
    width: auto;
    margin: 0;
    top: 28px;
    right: 15px;
    cursor: pointer;
    color: bisque;
  }
}
