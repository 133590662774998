.searchDetailsContainer {
  margin-bottom: 20px;

  h4 {
    text-transform: capitalize;
  }

  .searchDetailsItems {
    display: flex;
    flex-wrap: wrap;
  }
}
