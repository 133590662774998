.App {
    height: 100vh;
    position: relative;
}

.rootBackdrop {
    background-color: #111725d6;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100vw;
    transition: all 0.5s ease;
    z-index: 9999;
}

.rootBody {
    margin-left: 220px;
    padding: 20px 30px;
    padding-bottom: 40px;
    border: 1px solid transparent;
    border-right: 0;
    /* margin-right: -20px; */
    border-radius: 40px 0 0 40px;
    min-height: 100vh;
    background: #0f131d;

    .headerContainer {
        margin-top: -20px;
        margin: -20px -20px 20px -20px;
        padding: 10px 20px;
        border-bottom: 1px solid #1a2133;
        border-top: 1px solid #1a2133;
        text-align: right;
        display: flex;
        justify-content: flex-end;

        .emailVerificationNotice {
            font-weight: 600;
            display: flex;
            align-items: center;

            svg {
                stroke: rgb(163, 76, 76);
                font-size: 15px;
                margin-right: 5px;
            }
        }

        @media only screen and (max-width: 855px) {
            background-color: #0f131d;
            z-index: 999;
        }
    }

    .routes {
        width: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        // margin-bottom: 40px;

        .routesBody {
            width: 100%;
        }
    }

    @media only screen and (max-width: 750px) {
        width: calc(100vw - 0px);
        margin-left: 0px;
    }
}

.buyMeCoffee {
    position: fixed;
    display: flex;
    right: 20px;
    bottom: 20px;
    z-index: 999;

    button {
        &:first-of-type {
            margin-right: 5px;
        }
    }

    .productHuntIcon {
        fill: #da552f;
        font-size: 23px;
    }
}
