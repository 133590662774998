@import '../../../shared/styles/fonts.scss';

.noteItemContainer {
    position: relative;

    .noteItem {
        width: 300px;
        flex: 0 0 auto;
        padding: 20px;
        border: 2px solid #111725;
        border-radius: 15px;
        transition: 0.2s ease all;

        // &:first-of-type {
        //     border-left: 0;
        // }

        &__optionsOpen {
            opacity: 0.3;
        }

        .noteItemHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                color: bisque;
                padding-bottom: 5px;
                display: flex;
                align-items: center;

                span {
                    margin-left: 5px;
                    display: flex;
                    align-items: center;
                }
            }

            .noteItemTime {
                .formattedTime {
                    font-family: $zenDots;
                    color: bisque;
                    font-size: 11px;
                }

                .fullDateTime {
                    font-size: 11px;
                }
            }
        }

        .noteItemContent {
            overflow-y: auto;
            height: 320px;
            position: relative;
            overflow-x: hidden;
            margin-top: 20px;

            .quill {
                .ql-editor {
                    font-family: 'Avenir', -apple-system, BlinkMacSystemFont,
                        'Segoe UI', 'Open Sans', sans-serif;
                    padding: 0;

                    ul,
                    ol {
                        padding-left: 0;
                    }
                }
            }

            .lockContainer {
                position: absolute;
                top: 40%;
                left: 50%;
                margin: 0;
                width: 100%;
                transform: translate(-50%, -50%);
                height: 320px;
                display: flex;
                justify-content: center;
                align-items: center;

                h3 {
                    margin-left: 0;
                }
            }

            &__grid {
                display: none;
            }
        }

        @media only screen and (max-width: 855px) {
            // border-right: 0;
            width: 100%;
        }

        &__grid {
            border-radius: 15px;
            width: unset;

            .noteItemHeader {
                cursor: pointer;
            }

            &:hover {
                -webkit-box-shadow: 0px 24px 22px -25px #1d3165;
                box-shadow: 0px 24px 22px -25px #1d3165;
            }
        }
    }

    .noteClose {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .deleteContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 0;
        width: 70%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 15px;
        background-color: #1a2133;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .deleteOptions {
            margin-top: 10px;
            display: flex;
            justify-self: center;
            align-items: center;
        }

        > svg {
            color: #fff;
            font-size: 50px;
        }
    }

    &__maximized {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #111725;
        z-index: 999;
        font-size: 140%;

        .noteItem {
            width: 70%;
            margin: auto;
            padding-top: 60px;
            border: 0 !important;

            &__optionsOpen {
                opacity: 0.3;
            }

            .noteItemContent {
                display: block;
                height: calc(100vh - 220px);

                p {
                    line-height: 30px;
                    font-size: 16px;
                }
            }

            @media only screen and (max-width: 750px) {
                width: 100%;
            }
        }
    }
}
