@import 'src/shared/styles/fonts.scss';

.wordSearchContainer {
    height: 100vh;
    background-color: #0f131d;
    z-index: 99999;

    .wordSearchHeader {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .wordSearch {
            position: relative;
            width: 300px;
            max-width: 70%;

            input {
                margin: 0;
                color: bisque;
                padding: 12px 20px;
                border-radius: 0;
                font-size: 20px;
                font-weight: 500;
                border-radius: 25px;
            }

            svg {
                position: absolute;
                right: 20px;
                top: 15px;
                stroke: #3f4f7b;
                fill: #3f4f7b;
            }
        }

        @media only screen and (max-width: 750px) {
            width: calc(100% - 40px);
            margin-left: 40px;
        }
    }

    .searchItems {
        margin-top: 20px;
        display: flex;
        width: 100%;

        &.mobileSearch {
            display: none;

            .expandContainer {
                text-align: center;
                margin-top: 20px;

                p {
                    color: bisque;
                    font-size: 13px;
                    font-weight: 500;
                    cursor: pointer;
                    width: fit-content;
                    margin: auto;
                }
            }
        }

        @media only screen and (max-width: 750px) {
            flex-direction: column;

            &.mobileSearch {
                display: block;
            }

            &.webSearch {
                display: none;
            }
        }
    }

    @media only screen and (max-width: 750px) {
        width: 100%;
        margin-left: 0;
        padding-top: 0;
    }
}
