.addToCollectionContainer {
  position: relative;
  height: 100%;

  .addToCollectionOverview {
    margin-bottom: 20px;
    margin-top: 20px;

    p {
      &:first-of-type {
        font-weight: 600;

        span {
          color: bisque;
        }
      }

      &:nth-of-type(2) {
        font-weight: 300;
        font-size: 11px;

        span {
          color: bisque;
          font-weight: 600;
        }
      }
    }
  }
}
