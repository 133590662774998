.profileContainer {
    display: flex;
    flex-direction: row;

    .profileNav {
        width: 120px;
        position: fixed;

        ul {
            display: flex;
            flex-direction: column;
            list-style: none;

            li {
                a {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    margin-bottom: 10px;
                    color: #3f4f7b;
                    font-size: 15px;
                    text-decoration: none;
                    cursor: pointer;
                    transition: 0.2s ease all;

                    &:hover {
                        color: bisque;
                    }

                    @media only screen and (max-width: 750px) {
                        font-size: 13px;
                        margin-bottom: 0;
                        border-bottom: 3px solid #0f131d;
                    }
                }

                svg {
                    margin-right: 5px;
                }

                .active {
                    font-weight: 700;
                    color: bisque;
                    transition: 0.2s ease all;

                    @media only screen and (max-width: 750px) {
                        border-bottom-color: bisque;
                    }
                }
            }

            @media only screen and (max-width: 750px) {
                flex-direction: row;
                justify-content: flex-end;
                position: relative;
                height: unset;
                border-right: 0;
                overflow-y: auto;
                width: 100%;
            }
        }

        @media only screen and (max-width: 750px) {
            border-bottom: 1px solid #1a2133;
            position: sticky;
            top: 0;
            background-color: #0f131d;
            z-index: 1;
            width: 100%;
            margin-bottom: 30px;
        }
    }

    .profileBody {
        width: 100%;
        margin-left: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 750px) {
            margin-left: 0;
        }

        form,
        .form {
            width: 300px;
            border: 2px solid #111725;
            border-radius: 20px;
            padding: 20px;
        }
    }

    @media only screen and (max-width: 750px) {
        flex-direction: column !important;
    }
}
