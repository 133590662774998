.wordsListControls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 20px;
    z-index: 10;
    margin-bottom: 20px;

    .selectedIndicator {
        position: absolute;
        background-color: #1d3165;
        color: bisque;
        font-weight: 600;
        font-size: 10px;
        border-radius: 10px;
        padding: 4px;
        min-width: 25px;
        border: 2px solid #0f131d;
        right: -13px;
        top: -13px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .wordsCount {
        background-color: #111725;
        color: #3f4f7b;
        border: 1px solid #3f4f7b;
        font-weight: 500;
        border-radius: 15px;
        padding: 3px 10px;
        margin-left: 10px;
    }

    @media only screen and (max-width: 750px) {
        top: 80px;
    }
}

.wordsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-gap: 25px;
}

.viewMoreContainer {
    text-align: center;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: bisque;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
    }
}

.wordsLoaderContainer {
    height: 400px;
}
