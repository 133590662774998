.addReferenceContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > div {
    width: 100%;

    .addReferenceControls {
      display: flex;
      justify-content: space-between;
      margin-left: auto;

      .addReferenceControlsButtons {
        display: flex;
        align-items: center;
        justify-self: center;

        button {
          &:first-of-type {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
