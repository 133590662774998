@import 'src/shared/styles/mixins.scss';

.addWordContainer {
  height: calc(100% - 60px);
  overflow-y: auto;

  .addWordOverview {
    margin-bottom: 20px;
    margin-top: 20px;

    p {
      font-weight: 600;

      span {
        color: bisque;
      }
    }
  }

  .addWordSection {
    padding: 10px 0;

    input {
      margin: 0;
    }

    textarea {
      margin: 0;
    }
  }

  .picker {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .buttonContainer {
    position: fixed;
    bottom: 10px;
    right: 0;
    left: 0;
    padding: 0 20px;

    @media only screen and (max-width: 750px) {
      bottom: 25px;
    }
  }
}

.errorMessage {
  @extend %errorMessage;
}
