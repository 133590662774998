.sideBarContainer {
    transform: translate(120%);
    position: fixed;
    top: 40px;
    right: 40px;
    width: 500px;
    height: calc(100vh - 80px);
    max-height: 650px;
    margin: 0 0 -20px 20px;
    padding: 30px;
    transition: 0.3s ease all;
    background-color: #0f131d;
    border-radius: 25px;
    z-index: 9999;

    &__open {
        transform: translate(0);
        // box-shadow: 0px 0px 50px -30px #1d3165;
    }

    .sideBarHeader {
        border-bottom: 1px solid #1a2133;
        padding-bottom: 20px;
        background-color: #0f131d;

        h3 {
            display: flex;
            color: bisque;
            padding-bottom: 0;
        }

        .sideBarHeaderIcon {
            position: absolute;
            top: 0;
            left: 0;
            border-top-left-radius: 13px;
            border-bottom-right-radius: 10px;
            border-left: 0;
            border-bottom: 0;
            font-size: 13px;
            padding: 3px;
            font-weight: 700;
            color: #3f4f7b;
            background-color: #1a2133;
        }

        .sideBarRenderleft {
            display: flex;
            align-items: center;
            margin-left: 5px;

            svg {
                margin-right: 5px;
            }
        }
    }

    .sideBarContent {
        overflow-y: scroll;
        height: calc(100% - 45px);
        background-color: inherit;
    }

    @media only screen and (max-width: 750px) {
        top: 20px;
        width: calc(100% - 40px);
        height: calc(100vh - 40px);
        max-height: unset;

        &__open {
            transform: translate(20px, 0);
            z-index: 999999;
        }
    }
}
