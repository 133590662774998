.header {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;

    h2 {
        padding: 0;
        display: flex;
        color: bisque;
    }

    .headerRight {
        display: flex;
        align-items: center;
        justify-content: center;

        .mobileButton {
            display: none;

            @media only screen and (max-width: 750px) {
                display: block;
            }
        }

        .webButton {
            @media only screen and (max-width: 750px) {
                display: none;
            }
        }
    }
}
