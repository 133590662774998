@import 'shared/styles/fonts.scss';

.wordDefinitionsListItem {
  padding-bottom: 10px;
  position: relative;

  .definition {
    font-weight: 400;
    display: flex;
    justify-content: space-between;
    position: relative;

    .definitionDetails {
      font-weight: 400;
      transition: 0.2s ease all;

      &__blurred {
        opacity: 0.2;
      }

      .definitionItem {
        font-size: 10px;
        font-weight: 300;
        margin-right: 3px;
        color: gray;
      }
    }
  }

  .examples {
    margin-top: 2px;
    display: flex;
    flex-wrap: wrap;

    span {
      // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', $openSans;
      // font-weight: 300;
      font-size: 13px;
      font-weight: 100;
      color: gray;
      text-decoration: underline;
    }

    .divider {
      padding: 0 5px;
      font-size: 9px;
      text-decoration: none !important;
    }
  }
}
