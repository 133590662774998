.searchItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 20px 20px 20px;
    border-radius: 10px;
    background-color: #111725;
    cursor: pointer;
    position: relative;

    .searchItemDefinition {
        width: 100%;

        h4 {
            font-weight: 600;
        }

        textarea {
            margin: 0;
            margin-top: 10px;
            background: #0f131d;
        }
    }

    .searchItemDetails {
        width: 100%;
    }

    .example {
        .divider {
            padding: 0 5px;
        }
    }

    .searchItemControls {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;

        .searchItemControlsButtons {
            display: flex;
            align-items: center;
            justify-self: center;

            button {
                &:first-of-type {
                    margin-right: 5px;
                }
            }
        }
    }

    .searchItemFooter {
        position: absolute;
        bottom: -10px;
        border-radius: 50%;
        border-left: 0;
        border-bottom: 0;
        font-size: 12px;
        padding: 5px;
        font-weight: 700;
        color: #1a2133;
        height: 25px;
        width: 25px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        background-color: bisque;

        svg {
            stroke: #1a2133;
            font-size: 15px;
            stroke-width: 3px;
        }
    }
}
