.tabsContainer {
  min-height: 100%;
  position: relative;
  background-color: inherit;

  .tabsNav {
    display: flex;
    // background-color: #0f131d;
    background-color: inherit;
    border-bottom: 1px solid #1a2133;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 99;

    .link {
      display: block;
      padding: 10px;
      color: #3f4f7b;
      text-decoration: none;
      cursor: pointer;
      border-bottom: 1px solid #0f131d;
      transition: 0.2s ease all;

      &:hover {
        border-bottom-color: #3f4f7b;
      }

      &__active {
        border-bottom-color: bisque;
        font-weight: 500;
        color: bisque;
        transition: 0.2s ease all;

        &:hover {
          border-bottom-color: bisque;
        }
      }

      @media only screen and (max-width: 750px) {
        border-right: 0;
        border-left: 0;
        margin-bottom: 0;
        border-bottom: 1px solid #0f131d;

        &__active {
          border-bottom-color: bisque;
        }
      }
    }

    @media only screen and (max-width: 750px) {
      margin: 0 auto 0px auto;
      flex-direction: row;
      height: unset;
      border-right: 0;
      border-bottom: 1px solid #1a2133;
    }
  }

  .tabsContent {
    margin-top: 20px;

    @media only screen and (max-width: 750px) {
      margin-left: 0;
    }

    form {
      width: 300px;
    }
  }
}
