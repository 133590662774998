.pickerContainer {
  border: 1px solid #1a2133;
  border-radius: 15px;
  display: flex;
  width: fit-content;
  height: fit-content;
  transition: 0.2s ease all;
  margin-right: 5px;

  .pickerItem {
    padding: 5px 10px;
    margin: 5px;
    transition: 0.3s ease all;
    cursor: pointer;
    border-radius: 10px;
    height: 26px;
    display: flex;
    align-items: center;
    color: gray;
    font-size: 12px;

    &__active {
      background-color: #1d3165;
      color: bisque;
      font-weight: 500;
    }

    &:first-of-type {
      border-radius: 10px;
    }
  }

  &__disabled {
    opacity: 0.5;

    .pickerItem {
      cursor: not-allowed;
    }
  }
}
