@import 'src/shared/styles/fonts.scss';

.logo {
  margin: 20px auto;
  width: 180px;
  font-family: $MontAlt;
  font-size: 45px;
  color: bisque;
  display: flex;
  justify-content: center;

  &__pulse {
    animation: pulse 2.5s linear infinite;
  }

  &__small {
    width: 130px;
    margin: 0;
    padding: 0;
  }

  &__shortForm {
    width: 40px;
    border-radius: 50%;
    opacity: 0.9;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(01);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}
