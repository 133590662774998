@import '../../../shared/styles/global.scss';
@import '../../../shared/styles/mixins.scss';
@import '../../../shared/styles/fonts.scss';

.authContainer {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100vh;
    margin-top: -40px;

    .authLeft {
        width: 30%;
        background-color: #111623;
        position: fixed;
        width: 400px;
        height: 100%;
        padding: 40px;

        .authLeftContainer {
            height: 100%;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: space-between;

            .logo {
                height: 70px;
                width: 70px;
            }

            p {
                color: #c8c8c8;
                text-align: center;
                font-size: 13px;
                font-weight: 600;
                line-height: 20px;

                .textHightlight {
                    color: #ffc683;
                    font-weight: 400;
                }
            }
        }

        @media only screen and (max-width: 768px) {
            display: none;
        }
    }

    .authRight {
        width: 100%;
        position: relative;
        margin-left: 400px;
        padding: 40px;
        /* height: 100%; */
        background: #0f131d;

        .mobileLogo {
            display: none;

            @media only screen and (max-width: 768px) {
                display: block;
                margin-top: 20px;
            }
        }

        .formContainer {
            width: 300px;
            margin: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            .alternateAuth {
                display: flex;
                padding-top: 20px;
                margin-top: 20px;
                border-top: 1px solid #1a2133;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .alternateAuthButtons {
                    display: flex;
                    align-items: center;

                    .googleButton,
                    .twitterButton {
                        background-color: inherit;
                        border: 2px solid #1a2133;
                        width: 65px;
                        height: 50px;
                        margin: 10px 5px;

                        svg {
                            font-size: 30px;
                        }
                    }

                    .twitterButton {
                        svg {
                            fill: #1da1f2;
                        }
                    }
                }
            }

            @media only screen and (max-width: 480px) {
                width: 80%;
            }
        }

        @media only screen and (max-width: 768px) {
            margin-left: 0;
        }
    }

    .authFooter {
        display: flex;
        justify-content: center;

        a {
            color: #fff;
            text-decoration: underline;
            font-weight: 400;
            cursor: pointer;
        }
    }
}

.errorMessage {
    @extend %errorMessage;
}
