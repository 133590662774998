.wordOptions {
  margin-left: 5px;
  position: relative;
  display: flex;
  height: 23px;

  .moreOptionsContainer {
    position: unset;
  }

  span {
    &:not(:first-of-type) {
      svg {
        margin-left: 1px;
        margin-right: 3px;
      }
    }
  }
}
