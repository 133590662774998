.activityGraphs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 30px;
  margin-bottom: 30px;
  width: 100%;

  @media only screen and (min-width: 1145px) {
    // width: 80%;
  }

  @media only screen and (max-width: 440px) {
    display: block;
    margin-bottom: 0;

    > div {
      margin-bottom: 30px;
    }
  }
}
