.collectionItem {
    padding: 20px;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: 0.3s ease all;
    border: 2px solid #111725;
    height: 200px;

    &:hover {
        transform: translateY(-5px);
        -webkit-box-shadow: 0px 24px 22px -25px #1d3165;
        box-shadow: 0px 24px 22px -25px #1d3165;
    }

    .collectionImageContainer {
        background-size: cover;
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: 0px;
        height: 120px;
        width: 120px;
        background-position: center;
        border-radius: 100%;
        border: 10px solid #1a2133;
        transition: 0.3s ease all;
    }

    .collectionDetails {
        position: absolute;
        right: 20px;
        left: 20px;

        .collectionHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .headerContent {
                display: flex;
                align-items: center;
                width: 100%;
                display: block;

                h3 {
                    color: bisque;
                    margin-right: 10px;
                    padding: 0;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        label {
            display: block;
            font-weight: 300;
            font-size: 11px;
            margin-bottom: 3px;
            color: #c8c8c8;
        }

        .collectionContent {
            margin-top: 20px;

            .description {
                margin-bottom: 20px;

                p {
                    font-size: 13px;
                    font-weight: 500;
                    color: #c8c8c8;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .words {
                display: flex;
                margin-top: 6px;
                overflow-x: scroll;

                .noWords {
                    display: flex;
                    justify-content: center;
                    border: 1px solid #3f4f7b;
                    color: #3f4f7b;
                    font-weight: 500;
                    border-radius: 15px;
                    padding: 3px 10px;
                    margin-right: 5px;
                    margin-bottom: 5px;
                }

                span {
                    &:last-of-type {
                        background-color: unset;
                        padding-left: 5px;
                        color: #3f4f7b;
                        border: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
}
